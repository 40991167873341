<template>
	<div>
		<div class="h-handle-bg">
			<div class="h-handle-button">
				<div class="h-b"><el-button type="primary" size="small" @click="saveConfig">保存</el-button></div>
			</div>
		</div>
		<div class="form-list">
			<el-form ref="_customerAddressForm" :model="dataSource">

				<el-form-item>
					<el-col :span="8" class="form-title"><span style="color:red;">*</span>登录页Logo：</el-col>
					<el-col :span="7">
						<el-form-item prop="LoginLogo">
							<el-upload :action="uploadUrl" ref="loginload" :file-list="dataSource.LoginFileList"
								:auto-upload="true" accept=".jpg,.png" :data="fileData" :on-success="loginUploadSuccess"
								:limit="1" list-type="picture-card" :on-change="loginChange"
								:before-upload="beforeLoginUpload">
								<i class="el-icon-plus"></i>
							</el-upload>
						</el-form-item>
					</el-col>
				</el-form-item>
				<el-form-item>
					<el-col :span="8" class="form-title"><span style="color:red;">*</span>系统Logo：</el-col>
					<el-col :span="7">
						<el-form-item prop="PlatformLogo">
							<el-upload :action="uploadUrl" ref="platformload" :file-list="dataSource.PlatformFileList"
								:auto-upload="true" accept=".jpg,.png" :data="fileData1" :limit="1"
								:on-success="platformUploadSuccess" list-type="picture-card" :on-change="platformChange"
								:before-upload="beforePlatformUpload">
								<i class="el-icon-plus"></i>
							</el-upload>
						</el-form-item>
					</el-col>
				</el-form-item>
				<el-form-item>
					<el-col :span="8" class="form-title"><span style="color:red;">*</span>系统ICO：</el-col>
					<el-col :span="7">
						<el-form-item prop="IconLogo">
							<el-upload :action="uploadUrl" ref="iconload" :file-list="dataSource.IconFileList"
								:auto-upload="true" accept=".ico" :data="fileData2" :limit="1"
								:on-success="iconUploadSuccess" list-type="picture-card" :on-change="iconChange"
								:before-upload="beforeIconUpload">
								<i class="el-icon-plus"></i>
							</el-upload>
						</el-form-item>
					</el-col>
				</el-form-item>
				<el-form-item>
					<el-col :span="8" class="form-title"><span style="color:red;">*</span>网站名称：</el-col>
					<el-col :span="7">
						<el-form-item prop="Name">
							<el-input v-model="dataSource.Name" :maxlength="30" :minlength="4"
								placeholder="网站名称"></el-input>
						</el-form-item>
					</el-col>
				</el-form-item>
				<el-form-item>
					<el-col :span="8" class="form-title"><span style="color:red;">*</span>网站title：</el-col>
					<el-col :span="7">
						<el-form-item prop="Title">
							<el-input v-model="dataSource.Title" :maxlength="30" :minlength="4"
								placeholder="网站title"></el-input>
						</el-form-item>
					</el-col>
				</el-form-item>



			</el-form>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				uploadUrl: window.AppConfig.apiCustomerUrl + "omsapi/files/upload?currentType=" + this.$store.state.global
					.currentType + "&currentCCode=" + this.$store.state.global.currentCCode + "&token=" + this.Utils
					.getToken(),
				fileData: {
					FileType: 500
				},
				fileData1: {
					FileType: 500
				},
				fileData2: {
					FileType: 500
				},
				dataSource: {}
			}
		},
		props: {
			config: {
				isDetailDisplay: false
			},
		},
		mounted() {
			this.getInitData();
		},
		watch: {
			dataSource: {
				handler(curVal, oldVal) {
					//this.isDisabled = this.Utils.emptyGuid != this.dataSource.CustomerAddressID;
				},
				deep: true
			}
		},
		created: function() {},
		methods: {
			beforeLoginUpload(file) {
				const picType = (file.type === 'image/jpeg') || (file.type === 'image/png');
				const isLt2M = file.size / 1024 / 1024 < 2;
				console.log("校验图片类型");
				if (!picType) {
					this.$message.error('上传图片只能是 JPEG和PNG 格式!');
					for (var i = 0; i < this.dataSource.LoginFileList.length; i++) {
						if (this.dataSource.LoginFileList[i].uid === file.uid) this.dataSource.LoginFileList.splice(i, 1);
					}
				}
				console.log("校验图片大小");
				if (!isLt2M) {
					this.$message.error('上传图片大小不能超过 2MB!');
					for (var i = 0; i < this.dataSource.LoginFileList.length; i++) {
						if (this.dataSource.LoginFileList[i].uid === file.uid) this.dataSource.LoginFileList.splice(i, 1);
					}
				}
				return picType && isLt2M;
			},
			loginUploadSuccess(response, file, fileList) {
				console.log("coverUploadSuccess");
				console.log(response);
				console.log(file);
				console.log(fileList);
				this.dataSource.LoginFileList.push()
			},
			loginChange(file, fileList) {
				this.dataSource.LoginFileList = fileList.slice(-1);
			},
			beforePlatformUpload(file) {
				const picType = (file.type === 'image/jpeg') || (file.type === 'image/png');
				const isLt2M = file.size / 1024 / 1024 < 2;
				console.log("校验图片类型");
				if (!picType) {
					this.$message.error('上传图片只能是 JPEG和PNG 格式!');
					for (var i = 0; i < this.dataSource.PlatformFileList.length; i++) {
						if (this.dataSource.PlatformFileList[i].uid === file.uid) this.dataSource.PlatformFileList.splice(
							i, 1);
					}
				}
				console.log("校验图片大小");
				if (!isLt2M) {
					this.$message.error('上传图片大小不能超过 2MB!');
					for (var i = 0; i < this.dataSource.PlatformFileList.length; i++) {
						if (this.dataSource.PlatformFileList[i].uid === file.uid) this.dataSource.PlatformFileList.splice(
							i, 1);
					}
				}
				return picType && isLt2M;
			},
			platformUploadSuccess(response, file, fileList) {
				console.log("coverUploadSuccess");
				console.log(response);
				console.log(file);
				console.log(fileList);
			},
			platformChange(file, fileList) {
				this.dataSource.PlatformFileList = fileList.slice(-1);
			},
			beforeIconUpload(file) {
				debugger;
				const picType = (file.type === 'image/x-icon');
				const isLt2M = file.size / 1024 / 1024 < 2;
				console.log("校验图片类型");
				if (!picType) {
					this.$message.error('上传图片只能是ICO格式!');
					for (var i = 0; i < this.dataSource.IconFileList.length; i++) {
						if (this.dataSource.IconFileList[i].uid === file.uid) this.dataSource.IconFileList.splice(
							i, 1);
					}
				}
				console.log("校验图片大小");
				if (!isLt2M) {
					this.$message.error('上传图片大小不能超过 2MB!');
					for (var i = 0; i < this.dataSource.IconFileList.length; i++) {
						if (this.dataSource.IconFileList[i].uid === file.uid) this.dataSource.IconFileList.splice(
							i, 1);
					}
				}
				return picType && isLt2M;
			},
			iconUploadSuccess(response, file, fileList) {
			},
			iconChange(file, fileList) {
				this.dataSource.IconFileList = fileList.slice(-1);
			},
			saveConfig() {
				var _this = this;
				var url = "omsapi/websiteconfig/create";
				if (_this.dataSource.Id) {
					url = "omsapi/websiteconfig/edit";
				}

				if (this.dataSource.LoginFileList) {
					var file = this.dataSource.LoginFileList[0];

					if (file.status === "success") {
						_this.dataSource.LoginLogo = file.response ? file.response.Result.Url : file.url;
					}

				}
				if (this.dataSource.PlatformFileList) {
					var file = this.dataSource.PlatformFileList[0];
					if (file.status === "success") {
						_this.dataSource.PlatformLogo = file.response ? file.response.Result.Url : file.url;
					}

				}
				if (this.dataSource.IconFileList) {
					var file = this.dataSource.IconFileList[0];
					if (file.status === "success") {
						_this.dataSource.IconLogo = file.response ? file.response.Result.Url : file.url;
					}
				}

				_this.$ajax.query(url, "post", _this.dataSource, (result) => {
					if (result.IsSuccess) {
						_this.Utils.messageBox("保存成功.", "success");
						_this.getInitData();
					} else {
						_this.Utils.messageBox(data.OperationDesc, "error");
					}
				});

			},

			getInitData() {
				var _this = this;
				_this.$ajax.query("omsapi/websiteconfig/get", "get", {}, (result) => {
					if (result.Result) {
						console.log("result.Result:",result.Result);
						
						if(result.Result.LoginLogo){
							result.Result.LoginFileList=[];
							result.Result.LoginFileList.push({ uid: result.Result.LoginLogo, name:result.Result.LoginLogo, url: result.Result.LoginLogo });
						}
						if(result.Result.PlatformLogo){
							result.Result.PlatformFileList=[];
							result.Result.PlatformFileList.push({ uid: result.Result.PlatformLogo, name: result.Result.PlatformLogo, url: result.Result.PlatformLogo });
							
						}
						if(result.Result.IconLogo){
							result.Result.IconFileList=[];
							result.Result.IconFileList.push({ uid: result.Result.IconLogo, name: result.Result.IconLogo, url: result.Result.IconLogo });
							
						}
						_this.dataSource = result.Result;
					}
				});


			}


		}
	}
</script>

<style>
</style>